import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Results";

export const getClassListUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/class-list?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const downloadClassListUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/download-class-list?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const getResultsUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/course-grades?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const downloadScoreSheetUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/download-score-sheet?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const uploadScoreSheetUrl = ({ departmentCourseId }) =>
	`${baseUrl}/upload-result-score-sheet?departmentCourseId=${departmentCourseId}`;
export const getStudentCGPAsByIdUrl = (studentUserId) =>
	`${baseUrl}/student-semester-cgpa?studentUserId=${studentUserId}`;
export const getStudentCGPAsUrl = () => `${baseUrl}/student-semester-cgpa`;
export const getStudentSemesterResultUrl = (filter) =>
	`${baseUrl}/student-results?${generateUrlParams(filter)}`;
export const getGradeSheetUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/download-grade-sheet?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const getCourseGradesUrl = (filter) =>
	`${baseUrl}/course-grades?${generateUrlParams(filter)}`;
export const studentCompositeResultsUrl = (filter) =>
	`${baseUrl}/students-composite-results?${generateUrlParams(filter)}`;
