export const SEMESTERS = [
	{
		label: "Harmattan Semester",
		value: 1
	},
	{
		label: "Rain Semester",
		value: 2
	}
];

export const SCHOOL_DETAILS = {
	name: "Federal University of Technology, Owerri",
	location: "Imo State",
	pmb: "P.M.B 1526, Owerri, Imo State, Nigeria",
	shortForm: "FUTO"
};

export const PAYMENTTYPES = [
	{
		label: "Full Payment",
		value: "Full"
	},
	{
		label: "First Installment",
		value: "FirstInstallment"
	},
	{
		label: "Second Installment",
		value: "SecondInstallment"
	}
];

export const PAYMENTIDENTIFIER = {
	schoolFees: 1,
	acceptance: 2,
	hostel: "Hostel",
	sundry: 9
};

export const PAGESIZE = {
	sm: 5,
	md: 10,
	lg: 15,
	xl: 20
};

export const SEARCH_DELAY = {
	sm: 300,
	md: 500,
	lg: 800,
	xl: 1000
};

export const STATUS_OPTIONS = [
	{
		label: "Paid",
		value: true
	},
	{
		label: "Unpaid",
		value: false
	}
];

export const INITIAL_DATE = "0001-01-01T00:00:00";

export const MINIMUM_AGE = 15;
export const MAXIMUM_AGE = 80;
export const TENECE_SUPPORT_URL = "https://teneceschoolsupport.com/";

// Authentication Constants
export const TOKEN_HOLDER = `${SCHOOL_DETAILS.shortForm}token`;
export const USER_ROLE_HOLDER = `${SCHOOL_DETAILS.shortForm}userRole`;
export const USER_NAME_HOLDER = `${SCHOOL_DETAILS.shortForm}userName`;
export const BIRTHDAY_STATE_HOLDER = `${SCHOOL_DETAILS.shortForm}birthday`;
export const STUDENT_TYPE_HOLDER = `${SCHOOL_DETAILS.shortForm}studentType`;
export const USER_TYPES = ["lecturer", "student", "official"];

//Application mode id
export const APPLICATION_MODE = {
	UTME: 1,
	JUPEB: 2,
	DIRECT_ENTRY: 3,
	PG: 2,
	SUPPLEMENTARY: 3,
	PRE_DEGREE: 2,
	CCE: 4
};

export const APPLICATION_ID = {
	UTME: 1,
	JUPEB: 3,
	DIRECT_ENTRY: 4,
	PG: 2,
	SUPPLEMENTARY: 5,
	PRE_DEGREE: 10,
	CCE: 16
};

export const STUDENT_TYPES = {
	UNDERGRADUATE: 1,
	JUPEB: 2,
	PREDEGREE: 3,
	POSTGRADUATE: 4,
	CCEUNDERGRADUATE: 5,
	CCEPOSTGRADUATE: 6
};

export const PG_STUDENT_SEMESTERS = {
	PGD: "2 - 4 Semesters",
	MSC: "4 - 6 Semesters",
	PHD: "8 - 12 Semesters"
};
